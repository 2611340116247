.booking {
  max-width: 95%;
  margin: 0 auto;
}
.container {
  justify-content: space-evenly;
  border: 1px solid #707070;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}
.bookingCard {
  display: flex;
  justify-content: space-around;
  margin: 15px;
}
.text_color {
  color: #707070;
}
.heading {
  margin-bottom: 20px;
  text-align: center;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: -webkit-fill-available;
}
.center {
  align-items: center;
}
.textcenter {
  text-align: center;
  font-weight: 600;
}
.editIcon {
  margin-left: 10px;
  border: 1px solid #8ea0a0;
  border-radius: 50%;
  cursor: pointer;
}
.editIcon:hover {
  background-color: #f1f3f3;
  border-radius: 50%;
}
.modal {
  z-index: 3;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}
.content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
  animation: animatezoom 0.6s;
  border-radius: 10px;
}
.editItems {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 16px;
  padding: 16px;
}
.items {
  margin: 5px;
  padding: 5px;
  border: 1px solid #ff4f4f;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
}
.button {
  margin: 5px;
}
.selected {
  background: #fff2f4;
  border: 1px solid #ff4f4f;
}
.newSelected {
  background: green;
  color: white;
  border: 1px solid green;
}
.datepick input {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff4f4f;
  margin-right: 10px;
}
.clearFilter {
  padding: 10px;
  border: 1px solid #ff4f4f;
  display: inline-block;
  border-radius: 10px;
  color: #463d3e;
  cursor: pointer;
}
.exportBtn {
  padding: 10px;
  border: 1px solid #aaa;
  display: inline-block;
  border-radius: 10px;
  background-color: green;
  color: white;
  cursor: pointer;
}
.topSection {
  margin: 10px;
}
.filter {
  display: flex;
  float: right;
}

@media only screen and (max-width: 800px) {
  .bookingCard {
    flex-direction: column;
  }
  .content {
    width: 90%;
  }
}
