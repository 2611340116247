// Customizing Bootstrap for carbae.
// TODO: Move this to it's own npm like @whitrie/carbae-styles

$blue: #6995dd;
$red: #ff4f4f;
$primary: $red;
// $primary: #fd5757;
$info: #9fbae1;
$dark: #37131d;

$gray-100: #faf9f9 !default;
$gray-200: #f1eced !default;
$gray-300: #e6dfe0 !default;
$gray-400: #dbd0d2 !default;
$gray-500: #cec0c2 !default;
$gray-600: #c1afb1 !default;
$gray-700: #978587 !default;
$gray-800: #77696b !default;
$gray-900: #463d3e !default;

$font-family-sans-serif: "IBM Plex Sans", "Helvetica Neue", Arial, -apple-system,
  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", sans-serif;

// $theme-colors: (
//   "primary": #0074d9,
//   "danger": #ff4136,
//   "custom-color": #900
// );

// @function theme-color($key: "primary") {
//     @return map-get($theme-colors, $key);
//   }
// .custom-element {
//     background-color: theme-color("primary");
//   }
.card-img-top {
  min-height: 200px;
}

@import "~bootstrap/scss/bootstrap.scss";

// TODO: Import only needed.
// Custom.scss
// Option B: Include parts of Bootstrap

// Required
// @import "~bootstrap/scss/functions.scss";
// @import "~bootstrap/scss/variables.scss";
// @import "~bootstrap/scss/mixins.scss";

// Optional
// @import "~bootstrap/scss/reboot.scss";
// @import "~bootstrap/scss/type.scss";
// @import "~bootstrap/scss/images.scss";
// @import "~bootstrap/scss/code.scss";
// @import "~bootstrap/scss/grid.scss";
