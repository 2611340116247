body {
  margin: 0;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", sans-serif;
}

h3 {
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
td,
th {
  vertical-align: middle !important;
}
:root {
  --gutterWidth: 5px;
  --fsXxs: 10px;
  --fsXs: 12px;
  --fss: 14px;
  --fsm: 16px;
  --fsl: 18px;
  --fsXl: 28px;
  --fsXxl: 32px;
  --fsJumbo: 36px;

  /* Font Weight */
  --fwLight: 300;
  --fwNormal: 400;
  --fwSemi-bold: 600;
  --fwBold: 700;

  /* Extra Colors other than bootstrap*/
  --lightGrey: #bdc0bf;
  --lightestGrey: #bdc0bf38;
}

.logo {
  width: 64px;
  height: 64px;
}
