.header:global.navbar {
  padding: 0;
}

.img {
  max-width: 34px;
  height: auto;
  margin-right: 3px;
  color: #fff;
}
.navbtn:hover {
  color: #fff !important;
  background-color: red;
  border-radius: 8px;
}
.navbtn {
  color: #fff !important;
}
.header :global .navbar-brand {
  display: flex;
  align-items: center;
}

.container_wrapper {
  flex-wrap: nowrap !important;
  justify-content: space-around !important;
}
@media only screen and (max-width: 800px) {
  .brand {
    margin-right: 0px !important;
  }
}
