.booking {
  max-width: 95%;
  margin: 0 auto;
}
.bookingCard {
  display: flex;
  margin: 15px;
  justify-content: space-around;
  flex-direction: column;
}
.text_color {
  color: #707070;
}
.detailBox {
  border: 1px solid #707070;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
}
.model {
  display: flex;
  justify-content: space-evenly;
}
.user {
  margin: 0 auto;
}
.heading {
  margin-bottom: 20px;
  text-align: center;
}
.img {
  border-radius: 8px;
}
.showroomdetails {
  margin: 10px 0px;
}
.bookingData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: -webkit-fill-available;
}
.half {
  width: 50%;
  float: right;
  font-weight: bold;
  font-size: large;
}
.userhalf {
  margin-left: 5px;
  font-weight: bold;
  font-size: large;
}
.date {
  margin: 6px auto;
  text-align: center;
  font-weight: bold;
  font-size: large;
}
.selecteddate {
  text-align: center;
  margin: 6px auto;
}
.copy {
  display: flex;
  justify-content: space-between;
}
.copy img {
  margin-left: 5px;
  width: 20px;
  border: 1px solid black;
  padding: 2px;
  border-radius: 20%;
}
@media only screen and (max-width: 1000px) {
  .bookingCard {
    flex-direction: column;
  }
  .model {
    width: 100%;
    flex-direction: column;
  }
  .user {
    width: 100%;
  }
  .userhalf {
    /* font-weight: unset; */
    /* width: unset; */
    font-size: small;
  }
  .detailBox {
    margin: 10px auto;
  }
  .half {
    width: 50%;
  }
  .heading {
    font-size: x-large;
  }
  .text_color {
    font-size: small;
  }
}
