.login {
  max-width: 500px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.button_center {
  display: block;
  margin: 20px auto !important;
  padding: 12px 36px;
}
