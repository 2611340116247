.container {
  background-color: rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 10px;
  margin: 20px auto;
}
.img {
  height: 100%;
  width: 100%;
  border-radius: 8px !important;
}
.imgWrapper {
  height: 100px;
  max-width: 200px;
  text-align: right;
  margin-right: 50px;
}

.brandContainer {
  align-items: center;
}
.rightFlex {
  display: flex;
  flex-direction: row-reverse;
}
.margin {
  margin-left: 60px;
}

.welcomeContainer {
  margin-top: 50px;
  color: #515154;
}
.link {
  background-color: #fff;
  margin: 10px;
  padding: 10px;
  border: 1px solid #515154;
  border-radius: 10px;
  min-width: 23%;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  box-shadow: #515154 0px 0.25rem 0px, rgb(0 0 0 / 8%) 0px 4px 8px;
  color: #515154;
}
.link:hover {
  color: #515154;
  text-decoration: none;
  box-shadow: 0 0.375rem 0 #515154, 0px 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px) perspective(0) translate3d(0, 0, 0);
}
.linkContiner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 60px auto;
}
.first {
  box-shadow: rgb(255 110 35) 0px 0.25rem 0px, rgb(0 0 0 / 8%) 0px 4px 8px;
  color: rgb(255 110 35);
}
.first:hover {
  color: rgb(255 110 35);
  text-decoration: none;
  box-shadow: 0 0.375rem 0 rgb(255 110 35), 0px 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px) perspective(0) translate3d(0, 0, 0);
}
.second {
  box-shadow: rgb(17 174 255) 0px 0.25rem 0px, rgb(0 0 0 / 8%) 0px 4px 8px;
  color: rgb(17 174 255);
}
.second:hover {
  color: rgb(17 174 255);
  text-decoration: none;
  box-shadow: 0 0.375rem 0 rgb(17 174 255), 0px 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px) perspective(0) translate3d(0, 0, 0);
}
.third {
  box-shadow: rgb(0 184 143) 0px 0.25rem 0px, rgb(0 0 0 / 8%) 0px 4px 8px;
  color: rgb(0 184 143);
}
.third:hover {
  color: rgb(0 184 143);
  text-decoration: none;
  box-shadow: 0 0.375rem 0 rgb(0 184 143), 0px 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px) perspective(0) translate3d(0, 0, 0);
}
.fourth {
  box-shadow: rgb(179 66 255) 0px 0.25rem 0px, rgb(0 0 0 / 8%) 0px 4px 8px;
  color: rgb(179 66 255);
}
.fourth:hover {
  color: rgb(179 66 255);
  text-decoration: none;
  box-shadow: 0 0.375rem 0 rgb(179 66 255), 0px 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px) perspective(0) translate3d(0, 0, 0);
}
@media only screen and (max-width: 800px) {
  .linkContiner {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .margin {
    margin-left: unset;
    text-align: center;
  }
  .imgWrapper {
    text-align: center;
    margin-right: unset;
  }
  .rightFlex {
    flex-direction: unset;
    justify-content: center;
  }
}
