.home {
  padding-top: 30px;
}

.booking {
  cursor: pointer;
}
.home {
  margin: 30px auto;
}
.home :global .table-responsive {
  border: 1px solid #e6dfe0;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.home :global .table {
  margin-bottom: 0px;
}
.datepick input {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff4f4f;
  margin-right: 10px;
}
.clearFilter {
  padding: 10px;
  border: 1px solid #ff4f4f;
  display: inline-block;
  border-radius: 10px;
  color: #463d3e;
  cursor: pointer;
}
.exportBtn {
  padding: 10px;
  border: 1px solid #aaa;
  display: inline-block;
  border-radius: 10px;
  background-color: green;
  color: white;
  cursor: pointer;
}
.topSection {
  margin: 10px;
}
.filter {
  display: flex;
  float: right;
}
