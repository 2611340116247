.model {
  padding-top: 30px;
}
.modelCard {
  margin-top: 15px;
}
.home {
  margin: 30px auto;
}
.home :global .table-responsive {
  border: 1px solid #e6dfe0;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.home :global .table {
  margin-bottom: 0px;
}
.buttons :global .spinner-border {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

form :global .form-group {
  margin-bottom: 10px !important;
}

.modal {
  z-index: 3;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  color: #fff;
  font-size: 50px !important;
}
.content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
  animation: animatezoom 0.6s;
}
.paragraph {
  margin-bottom: 0;
}
.img {
  width: 100%;
  vertical-align: middle;
  border-style: none;
}
.imageCol {
  cursor: pointer;
}
.actions {
  display: flex;
  justify-content: space-around;
}
.actions button {
  margin: 0 6px;
}

.sideTab {
  float: left;
  background-image: linear-gradient(
    to bottom,
    #ff4f4f,
    #ff78a0,
    #f8a8da,
    #ead3f5,
    #f5f5f7
  );
  min-height: 100%;
  height: 75vh;
}
.fixed {
  padding: 10px;
  margin: 0;
  width: 200px;
  position: fixed;
}
.navItem {
  background-color: #28a745;
  padding: 5px;
  color: #fff;
  margin: 10px 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.navItem:hover {
  background-color: black;
}

.container {
  display: grid;
  grid-template-columns: 200px auto;
}
.active {
  border: 2px solid black;
}
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@media only screen and (max-width: 768px) {
  .modal {
    padding-top: 50px;
  }
  .content {
    width: auto;
    margin: 50px 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .fixed {
    position: relative;
    display: flex;
  }
  .navItem {
    width: 100px;
  }
  .sideTab {
    margin: 0px auto;
    background-image: none;
    min-height: auto;
    height: 100%;
  }
}
