.offerBox {
  border: 1px solid #ff4f4f;
  border-radius: 10px;
  margin: 30px auto;
  padding: 10px;
}

.detailContainer {
  background-color: #f5f5f7;
  border-radius: 10px;
  margin: 30px auto;
  padding: 10px;
}
.text {
  color: #707070;
  display: flow-root;
}
.textData {
  width: 70%;
  float: right;
  margin-left: 5px;
  font-weight: bold;
  font-size: large;
}
.createLinkButton:hover {
  text-decoration: none;
  background-color: white;
  color: #ff4f4f;
}
.createLinkButton {
  padding: 8px;
  background-color: #ff4f4f;
  color: white;
  border: 1px solid #ff4f4f;
  border-radius: 6px;
}
.createButtonContainer {
  margin: 20px auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}
.variantContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.variantBox {
  border: 1px solid black;
  min-width: 32%;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}
.variantBox:hover {
  background-color: #f5f5f7;
  text-decoration: none;
}
.variantName {
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 1;
  width: 100%;
  background: white;
  box-sizing: border-box;
  margin: 6px 0px;
  padding: 10px;
}
.variantFilter {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}
.selected {
  background: #fff2f4;
  border: 1px solid #ff4f4f !important;
  box-sizing: border-box;
  border-radius: 12px;
}
.bodyType {
  padding: 10px;
  margin: 10px;
  border: 1px solid #ff4f4f;
  border-radius: 12px;
  width: 120px;
  cursor: pointer;
}
.bodyname {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #303335;
  margin-bottom: 0px;
}
.offerLink {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  background-color: #ff4f4f;
  border-radius: 4px;
}
.offerLink:hover {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .textData {
    width: 50%;
  }
  .variantBox {
    min-width: auto;
  }
}
