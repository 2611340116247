.booking {
  max-width: 90%;
  margin: 0 auto;
}
.container {
  justify-content: space-evenly;
  border: 1px solid #707070;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}
.bookingCard {
  display: flex;
  justify-content: space-around;
  margin: 15px;
}
.text_color {
  color: #707070;
}
.heading {
  margin-bottom: 20px;
  text-align: center;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: -webkit-fill-available;
}
.center {
  align-items: center;
}
.margin {
  margin-left: 20%;
}

@media only screen and (max-width: 800px) {
  .bookingCard {
    flex-direction: column;
  }
  .content {
    width: 90%;
  }
  .margin {
    margin-left: unset;
  }
}
