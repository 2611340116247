.datepick input {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff4f4f;
  margin-right: 10px;
}
.clearFilter {
  padding: 10px;
  border: 1px solid #ff4f4f;
  display: inline-block;
  border-radius: 10px;
  color: #463d3e;
  cursor: pointer;
}
.exportBtn {
  padding: 10px;
  border: 1px solid #aaa;
  display: inline-block;
  border-radius: 10px;
  background-color: green;
  color: white;
  cursor: pointer;
}
.topSection {
  margin: 10px;
}
.filter {
  display: flex;
  float: right;
}
