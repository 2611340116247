.term a {
  text-decoration: none;
}
.font {
  font-size: large;
}
@media screen and (max-width: 768px) {
  .term h1 {
    font-size: larger !important;
  }
  .term h2 {
    font-size: medium !important;
  }
  .term h3 {
    font-size: medium !important;
  }
  .term li {
    font-size: small !important;
  }
  .term p {
    font-size: small !important;
  }
  .term ol {
    padding-inline-start: 16px;
  }
  .term ul {
    padding-inline-start: 16px;
  }
}
